<template>
    <div v-loading="loading">
        <ts-error v-if="message"></ts-error>
        <template v-else>
            <div :id="key" v-if="loaded" class="through-page-canvas"></div>
        </template>
        <through-table ref="through" @setCountField="setCountField" :col="cols" ></through-table>
    </div>
</template>

<script>
import ThroughTable from "./ThroughTable.vue"
import throughMixin from './throughMixin'
    export default {
        components: {ThroughTable},
        mixins: [throughMixin],
        props: ['message'],
        data() {
            return {
                key: 'throughComponentRight',
                activeName: '',

                dataLoading: false,
                model: {},
                option: [],

                loaded: true,

                config: {
                    component: {
                        type: 'component',
                        nextType: 'drug',
                        query: 'dr/baseDrugsComponent/queryBaseDrugsComponentPage',
                        queryField: 'comName',   // 查询参数字段
                        keywordField: 'component', // 搜索字段
                        showField: 'component',  // 显示字段
                        saleAmount:'saleAmount',
                        countField: 'qty',  // 计数字段
                        color: '#0CB64A',
                        countShow: '药品数量',
                    },
                    drug: {
                        type: 'drug',
                        nextType: 'component',
                        query: 'dr/baseDrugsComponent/queryBaseDrugsComponentPage',
                        queryField: 'component',   // 查询参数字段
                        keywordField: 'comName', // 搜索字段
                        showField: 'comName',  // 显示字段
                        saleAmount:'saleAmount',
                        countField: 'componentQty',  // 计数字段
                        // hideCount: true,
                        color: '#576EEB',
                        countShow: '成分数量',
                    },
                },
                cols:[
                    {key:'baseDrug',value:'基药'},
                    {key:'medicalInsurance',value:'医保'},
                ]
            }
        },
        mounted() {
            // this.remoteMethod('当归')
        },
        methods: {
            remoteMethod(keyword) {
                this.dataLoading = true
                this.$api.get('setting/drugsComponent/queryDrugsComponentListByKeyword', {keyword}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.success) {
                            this.option = res.data
                        } else {
                            this.message = res.message
                        }
                    })
                    .finally(() => {
                        this.dataLoading = false
                    })
            },
            init(model) {
                this.model = model
                this.loading = true
                this.loaded = false
                this.$nextTick(() => {
                    this.loaded = true
                })
                this.antv = null

                this.$api.post(this.config.drug.query, {
                    page: {
                        pageNo: 1,
                        pageSize: this.pageSize,
                    },
                    params: {
                        [this.config.drug.queryField]: this.model[this.config.drug.queryField],
                    }
                })
                    .then(res => {
                        if (res.data.records && res.data.records.length > 0) {
                            const obj = {
                                id: this.model.id,
                                loaded: true,
                                label: `${this.model.component}(${res.data.totalCount || 0})`,
                                data: this.model,

                                style: {
                                    fill: '#0CB64A',
                                },
                                children: this.getNodeList(
                                    res.data.records,
                                    this.config.drug,
                                    {
                                        parentTitle: this.model.component,
                                        parentModel: {data: this.model},
                                    }
                                ),
                            }

                            this.antv = this.renderG6(obj)

                            this.antv.on('node:click', evt => {
                                const item = evt.item
                                const model = item.getModel()
                                if (model.isFull) {
                                    this.$refs.through.init(model)
                                } else if (!model.loaded) {
                                    model.loaded = true
                                    this.loading = true
                                    const config = this.config[model.nextType]
                                    this.$api.post(config.query, {
                                        page: {
                                            pageNo: 1,
                                            pageSize: this.pageSize,
                                        },
                                        params: {
                                            [config.queryField]: model.data[config.queryField],
                                        }
                                    })
                                        .then(res => {
                                            setTimeout(() => {
                                                model.children = this.getNodeList(
                                                    res.data.records,
                                                    config,
                                                    {
                                                        parentTitle: model.clearLabel,
                                                        parentModel: model,
                                                    }
                                                )
                                                this.antv.changeData()
                                            }, 300)
                                        })
                                        .finally(() => {
                                            this.loading = false
                                        })
                                }
                            })
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
        }
    }
</script>